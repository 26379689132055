'use client'

import { Image } from '@/common/components/image/image'
import { BannerSliderItem } from '@/api'
import { selectors } from '@/common/constants/selectors-constants'

interface SlideComponentProps {
  isMobile: boolean
  slide: BannerSliderItem
  minPriority?: number | null
  onClick?: (slide: BannerSliderItem) => void
}

export function SlideComponent({
  isMobile,
  slide,
  minPriority,
  onClick,
}: SlideComponentProps) {
  const priority = slide.position === (minPriority || 0)
  return (
    slide.desktop_image && (
      <div
        onClick={() => {
          onClick?.(slide)
        }}
      >
        <a
          href={slide.url || ''}
          className="outline-0"
          data-test={selectors.HP.HPbanner}
        >
          {slide.mobile_image && isMobile ? (
            <Image
              src={slide.mobile_image}
              alt={slide.title || ''}
              width={500}
              height={500}
              className="w-full"
              priority={priority}
            />
          ) : (
            <Image
              src={slide.desktop_image}
              alt={slide.title || ''}
              width={2000}
              height={750}
              className="w-full "
              priority={priority}
            />
          )}
        </a>
      </div>
    )
  )
}
