import React, { FC, HTMLAttributes } from 'react'
import { cva } from 'class-variance-authority'

const variants = cva([], {
  variants: {
    variant: {
      h1: ['text-4xl', 'font-bold'],
      h2: ['text-3xl', 'font-bold'],
      h3: ['text-2xl', 'font-bold'],
      h4: ['text-xl', 'font-bold'],
      h5: ['text-lg', 'font-bold'],
      h6: ['text-base', 'font-bold'],
    },
  },
})

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const Heading: FC<HeadingProps> = (props) => {
  const { className, role, children, variant = 'h1' } = props
  const Component = variant as keyof JSX.IntrinsicElements

  return (
    <Component
      as={variant}
      role={role}
      className={variants({ variant, className })}
    >
      {children}
    </Component>
  )
}

Heading.displayName = 'Heading'
export { Heading }
