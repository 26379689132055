'use client'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './slick-theme-custom.css'
import { useMemo } from 'react'
import Slider from 'react-slick'

import { BannerSliderConfig, BannerSliderItem, Maybe } from '@/api'
import { SlideComponent } from './slide-component'

export type SliderComponentProps = {
  items: Maybe<BannerSliderItem>[]
  isMobile: boolean
  config?: Maybe<BannerSliderConfig>
  onClick?: (slide: BannerSliderItem, position: number) => void
}

export function SliderComponent({
  items,
  isMobile,
  config,
  onClick,
}: SliderComponentProps): JSX.Element | null {
  const settings = {
    dotsClass: `slick-dots ${isMobile && 'slick-dots-mobile'}`,
    dots: config?.show_dots ?? true,
    infinite: config?.infinite_loop ?? true,
    autoplaySpeed: config?.autoplay_speed ?? 0,
    autoplay: config?.autoplay ?? true,
    slidesToShow: 1,
    arrows: false,
    adaptiveHeight: true,
  }
  const now = Date.now()
  const sortedActiveSlides = useMemo(
    () =>
      items
        .filter((slide: BannerSliderItem) => {
          const activeFrom = slide.active_from
            ? Date.parse(slide.active_from)
            : 0
          const activeTo = slide.active_to
            ? Date.parse(slide.active_to)
            : Number.POSITIVE_INFINITY

          return now >= activeFrom && now <= activeTo
        })
        .sort(
          (slideA, slideB) => (slideA?.position || 0) - (slideB?.position || 0),
        ),
    [items, now],
  )

  const minPriority = sortedActiveSlides[0]?.position

  return sortedActiveSlides.length > 0 && (config?.enabled ?? true) ? (
    <div>
      <Slider {...settings}>
        {sortedActiveSlides.map(
          (slide, index) =>
            slide && (
              <SlideComponent
                key={slide.id}
                slide={slide}
                isMobile={isMobile}
                minPriority={minPriority}
                onClick={(slide) => onClick?.(slide, index + 1)}
              />
            ),
        )}
      </Slider>
    </div>
  ) : null
}
