import {
  GtmScheduler,
  GtmTrackerState,
} from '@/common/services/tracking/google/gtm-scheduler'
import { GtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { TrackingPage } from '@/common/services/tracking/google/gtm-types'

export function createPageGtmScheduler(): GtmScheduler {
  return new GtmScheduler(createPageGtmEventsOrder())
}

export function createPageGtmEventsOrder(): GtmTrackerState[] {
  return [
    GtmTrackerState.Started,
    GtmTrackerState.PageView,
    GtmTrackerState.User,
    GtmTrackerState.VirtualPageview,
  ]
}

export function trackPage({
  page,
  categories,
  gtmTracker,
  gtmScheduler,
  loaded,
  trackUser,
}: {
  page: TrackingPage
  categories: string[] | string
  gtmTracker: GtmTracker
  gtmScheduler: GtmScheduler
  loaded: boolean
  trackUser: () => void
}) {
  gtmScheduler.scheduleInitialEvent(
    GtmTrackerState.Started,
    gtmTracker.trackGtmStart,
  )
  gtmScheduler.scheduleInitialEvent(GtmTrackerState.PageView, () =>
    gtmTracker.trackPage(page, categories),
  )

  if (loaded) {
    gtmScheduler.scheduleInitialEvent(GtmTrackerState.User, trackUser)
  }
}
