import {
  useCategoriesByGoalQuery,
  useHomepageBannerSliderQuery,
  useNewestBlogPostsQuery,
} from '@/api'
import { HttpMethod } from '@/common/api/rest/fetch'
import { getQueryClientServer } from '@/common/services'
import { ProductListItem, StoreCodeType } from '@/common/types'
import { consoleError } from '@/common/utils/console'
import { handleServerErrors } from '@/common/utils/error-handling-utils'

export async function fetchHomepageQueries() {
  return await Promise.all([
    fetchCategoriesByGoalData(),
    fetchHomepageBannerSliderData(),
    fetchNewestBlogPostsData(),
  ])
}

export async function fetchCategoriesByGoalData() {
  const queryClient = getQueryClientServer()

  const categoriesByGoalQuery = await queryClient.fetchQuery({
    queryKey: useCategoriesByGoalQuery.getKey(),
    queryFn: useCategoriesByGoalQuery.fetcher(),
  })

  handleServerErrors(categoriesByGoalQuery)

  return categoriesByGoalQuery
}

export async function fetchHomepageBannerSliderData() {
  const queryClient = getQueryClientServer()

  const homepageBannerSliderQuery = await queryClient.fetchQuery({
    queryKey: useHomepageBannerSliderQuery.getKey(),
    queryFn: useHomepageBannerSliderQuery.fetcher(),
  })

  handleServerErrors(homepageBannerSliderQuery)

  return homepageBannerSliderQuery
}

export async function fetchNewestBlogPostsData() {
  const queryClient = getQueryClientServer()

  const newestBlogPostsQuery = await queryClient.fetchQuery({
    queryKey: useNewestBlogPostsQuery.getKey({
      currentPage: 1,
      pageSize: 10,
    }),
    queryFn: useNewestBlogPostsQuery.fetcher({
      currentPage: 1,
      pageSize: 10,
    }),
  })

  handleServerErrors(newestBlogPostsQuery)

  return newestBlogPostsQuery
}

export async function fetchTopProducts(
  lastViewedSKUs: string[],
  storeCode: StoreCodeType,
): Promise<ProductListItem[]> {
  try {
    const searchParams = new URLSearchParams()
    searchParams.append('storeCode', storeCode)
    searchParams.append('limit', '12')

    if (lastViewedSKUs.length > 0) {
      searchParams.append('skus', lastViewedSKUs.join(','))
    }

    const response = await fetch(
      `/api/v1/top-products?${searchParams.toString()}`,
      {
        method: HttpMethod.GET,
      },
    )

    if (!response.ok) {
      consoleError('homepage/homepage-api.ts [fetchTopProducts]', response)
      return []
    }

    const result = await response.json()

    return result
  } catch (err) {
    consoleError('homepage/homepage-api.ts [fetchTopProducts][catch]', err)
    return []
  }
}
