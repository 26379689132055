import { ReactNode, SVGProps } from 'react'

import { selectors } from '@/common/constants/selectors-constants'

export interface USPAreaItem {
  icon: (props: SVGProps<SVGSVGElement>) => ReactNode
  title: string
  subtitle: string
}

interface USPAreaComponentProps {
  items: USPAreaItem[]
}

export const USPAreaComponent = ({ items }: USPAreaComponentProps) => {
  return (
    <ul
      className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4"
      data-test={selectors.HP.benefits}
    >
      {items.map((item, index) => (
        <li className="flex gap-2" key={`USP_${index}`}>
          <item.icon className="shrink-0" />
          <div>
            <p className="text-base md:text-sm md:leading-tight">
              <strong
                dangerouslySetInnerHTML={{ __html: item.title }}
                className="text-[15px]"
              />
            </p>
            <p
              className="text-xs md:text-sm md:leading-relaxed"
              dangerouslySetInnerHTML={{ __html: item.subtitle }}
            />
          </div>
        </li>
      ))}
    </ul>
  )
}
