import { ProductFlashMessages } from '@/modules/product/components/flash-messages/flash-messages-component'
import { HomepageProductsGrid } from './homepage-products-grid'

type Props = {
  baseUrl: string
}

export function HomepageProducts({ baseUrl }: Props): JSX.Element {
  return (
    <div className="relative">
      <ProductFlashMessages />
      <HomepageProductsGrid baseUrl={baseUrl} />
    </div>
  )
}
