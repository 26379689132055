'use client'

import React, { useMemo } from 'react'
import Slider, { Settings as SliderSettings } from 'react-slick'

import { BlogPosts } from '@/api'
import { BlogPost } from './blog-post'
import { BlogSliderNextArrow } from './blog-slider-next-arrow'
import { BlogSliderPreviousArrow } from './blog-slider-previous-arrow'
import { selectors } from '@/common/constants/selectors-constants'

export type BlogComponentProps = {
  posts: BlogPosts
}

export function BlogComponent({ posts }: BlogComponentProps): JSX.Element {
  const settings = useMemo(
    () =>
      ({
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <BlogSliderNextArrow />, // OnClick is passed from react-slick
        prevArrow: <BlogSliderPreviousArrow />, // OnClick is passed from react-slick
        className: '-m-3',
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 639,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      } as SliderSettings),
    [],
  )

  return (
    <div className="mb-10" data-test={selectors.HP.blogHP}>
      <h2 className="uppercase text-black font-semibold leading-5 text-xl mt-6 mb-5">
        <a href="/blog">Blog</a>
      </h2>
      {posts?.items && (
        <Slider {...settings}>
          {posts.items.map(
            (blogPost) =>
              blogPost && <BlogPost key={blogPost.url} blogPost={blogPost} />,
          )}
        </Slider>
      )}
    </div>
  )
}
