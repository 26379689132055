import { Settings as SliderSettings } from 'react-slick'
import { Dispatch, SetStateAction } from 'react'

import {
  ProductsSliderNextArrow,
  ProductsSliderPreviousArrow,
} from './products-slider-arrows'

export const getSliderSettings = (
  activeSlide: number | null,
  setActiveSlide: Dispatch<SetStateAction<number | null>>,
): SliderSettings => ({
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: false,
  dots: false,
  arrows: true,
  nextArrow: <ProductsSliderNextArrow activeSlide={activeSlide} />,
  prevArrow: <ProductsSliderPreviousArrow activeSlide={activeSlide} />,
  beforeChange: (_current, next) => setActiveSlide(next),
})
