import { ArrowGbRightIcon } from '@/components/icons/svg'

export type BlogSliderNextArrowProps = {
  onClick?: () => void
  style?: React.CSSProperties
}

export function BlogSliderNextArrow({
  style,
  onClick,
}: BlogSliderNextArrowProps): JSX.Element {
  return (
    <ArrowGbRightIcon
      width="2.35rem"
      height="2rem"
      className="absolute right-[0.75rem] top-[-2rem] cursor-pointer hidden sm:block"
      style={style}
      onClick={onClick}
    />
  )
}
