import { twMerge } from 'tailwind-merge'

import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
} from '@/components/icons/svg'

export type ProductsSliderArrowProps = {
  onClick?: () => void
  style?: React.CSSProperties
  activeSlide: number | null
}

const START_SLIDE = 0
const END_SLIDE = 8 // 12 is the total number of products, 8 is the first slide on last page

export function ProductsSliderNextArrow({
  style,
  onClick,
  activeSlide,
}: ProductsSliderArrowProps): JSX.Element {
  return (
    <ArrowCircleRightIcon
      width="2.5rem"
      height="2.5rem"
      className={twMerge(
        'absolute right-0 top-1/3 cursor-pointer hidden sm:block z-10',
        activeSlide === END_SLIDE ? 'cursor-not-allowed' : '',
      )}
      style={activeSlide === END_SLIDE ? { opacity: 0.5 } : style}
      onClick={onClick}
    />
  )
}

export function ProductsSliderPreviousArrow({
  style,
  onClick,
  activeSlide,
}: ProductsSliderArrowProps): JSX.Element {
  return (
    <ArrowCircleLeftIcon
      width="2.5rem"
      height="2.5rem"
      className={twMerge(
        'absolute left-0 top-1/3 cursor-pointer hidden sm:block z-10',
        activeSlide === START_SLIDE ? 'cursor-not-allowed' : '',
      )}
      style={activeSlide === START_SLIDE ? { opacity: 0.5 } : style}
      onClick={onClick}
    />
  )
}
