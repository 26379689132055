import { ArrowGbLeftIcon } from '@/components/icons/svg'

export type BlogSliderPreviousArrowProps = {
  onClick?: () => void
  style?: React.CSSProperties
}

export function BlogSliderPreviousArrow({
  style,
  onClick,
}: BlogSliderPreviousArrowProps): JSX.Element {
  return (
    <ArrowGbLeftIcon
      width="2.35rem"
      height="2rem"
      className="absolute right-[4rem] top-[-2rem] cursor-pointer hidden sm:block"
      style={style}
      onClick={onClick}
    />
  )
}
